import {
  Box,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from "@chakra-ui/react"
import { SpecterProducts } from "@prisma/client"
import { ProductDetailItem, ProductItem } from "../SignalCard"
import { EntityActionModalRootScreen } from "./RootScreen"

type Props<P extends SpecterProducts> = {
  product: P
  signals: (ProductItem<P> | ProductDetailItem<P>)[]
  isOpen: boolean
  onClose: () => void
}

export const EntityActionModal = <P extends SpecterProducts>({
  product,
  signals,
  isOpen,
  onClose,
}: Props<P>) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent maxW={800} p={1} rounded="2xl">
        <Box
          overflow="hidden"
          rounded="xl"
          borderWidth={1}
          borderColor="gray.100"
        >
          <ModalCloseButton zIndex={1000} />
          <EntityActionModalRootScreen product={product} signals={signals} />
        </Box>
      </ModalContent>
    </Modal>
  )
}
