import { Box } from "@chakra-ui/react"

export const IconPeople = (props: any) => {
  return (
    <Box as="svg" viewBox="0 0 32 32" {...props}>
      <rect fill="#586eff" width="32" height="32" rx="9" ry="9" />
      <path
        fill="white"
        d="M16,5h0c-2.8,0-5.08,2.27-5.08,5.08h0c0,2.8,2.27,5.08,5.08,5.08h0c2.8,0,5.08-2.27,5.08-5.08h0c0-2.8-2.27-5.08-5.08-5.08ZM23.59,27h-15.19c-1.72,0-3.11-1.39-3.11-3.11h0c0-3.75,3.04-6.8,6.8-6.8h7.8c3.75,0,6.8,3.04,6.8,6.8h0c0,1.72-1.39,3.11-3.11,3.11Z"
      />
    </Box>
  )
}
