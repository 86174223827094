import {
  Button,
  ButtonGroup,
  ButtonGroupProps,
  ButtonProps,
} from "@chakra-ui/react"

import { capitalize } from "~/utils/string/format"

export const ToggleButton = <T extends string>({
  value,
  options,
  onChange,
  format = (v) => capitalize(v),
  groupProps,
  buttonProps,
}: {
  value: T
  options: T[]
  onChange: (value: T, index: number) => void
  format?: (value: T) => string
  groupProps?: ButtonGroupProps
  buttonProps?: ButtonProps
}) => {
  return (
    <ButtonGroup size="xs" isAttached {...groupProps}>
      {options.map((option) => (
        <Button
          key={option}
          fontSize="xx-small"
          onClick={() => onChange(option, options.indexOf(option))}
          variant={value === option ? "solid" : "outline"}
          colorScheme={
            value === option
              ? groupProps?.colorScheme ?? "brandDark"
              : undefined
          }
          {...buttonProps}
        >
          {format(option)}
        </Button>
      ))}
    </ButtonGroup>
  )
}
