import { useQuery } from "@tanstack/react-query"
import invariant from "~/utils/invariant"
import { cacheKeys } from "../cacheKeys"

export const useTalentSignalByIDQuery = (id: string) => {
  return useQuery(
    cacheKeys.talentSignal(id),
    async () => {
      const req = await fetch(`/api/signals/talent/${id}`)

      invariant(req.ok, `Failed to load talent signal ${id}`)

      return req.json()
    },
    {
      enabled: !!id,
    }
  )
}
