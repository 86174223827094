import { Flex, Heading, HStack, Text, VStack } from "@chakra-ui/react"
import { HiTranslate } from "react-icons/hi"
import { GetTalentSignalByID } from "~/utils/db/getTalentSignalByID"
import { isNullish } from "~/utils/values"
import { BoxIcon } from "~/components/BoxIcon"

interface Props {
  languages: GetTalentSignalByID["Languages"]
}

export const TalentSignalLanguages = ({
  languages,
}: Props): JSX.Element | null => {
  if (isNullish(languages)) {
    return null
  }

  return (
    <VStack alignItems="stretch">
      <Heading size="sm" as="h2">
        Languages
      </Heading>

      <Flex gap={4} flexWrap="wrap">
        {languages.split(", ").map((lang) => (
          <HStack alignItems="center" key={lang}>
            <BoxIcon icon={HiTranslate} />
            <Text fontSize="sm">{lang}</Text>
          </HStack>
        ))}
      </Flex>
    </VStack>
  )
}
