import { TagProps } from "@chakra-ui/react"
import { GrowthStage } from "@prisma/client"
import {
  companyGrowthBadgeTheme,
  GrowthBadgeTheme,
  GROWTH_STAGE_LABEL,
  strategicGrowthBadgeTheme,
} from "~/consts/signals"
import { isNullish } from "~/utils/values"
import { ColoredTag } from "../ColoredTag"

const GrowthBadge = ({
  growthStage,
  theme,
  ...props
}: {
  growthStage: GrowthStage
  theme: GrowthBadgeTheme
} & TagProps) => {
  const color = theme[growthStage]

  return (
    // ? I don't know why the hell this gives me a type error
    // @ts-ignore
    <ColoredTag color={color} {...props}>
      {GROWTH_STAGE_LABEL[growthStage]}
    </ColoredTag>
  )
}

export const StrategicGrowthBadge = ({
  growthStage,
  ...props
}: {
  growthStage: GrowthStage
} & TagProps) => {
  return (
    <GrowthBadge
      growthStage={growthStage}
      theme={strategicGrowthBadgeTheme}
      {...props}
    />
  )
}

export const CompanyGrowthBadge = ({
  growthStage,
  ...props
}: {
  growthStage: GrowthStage | null
} & TagProps) => {
  if (isNullish(growthStage)) {
    return null
  }

  return (
    <GrowthBadge
      growthStage={growthStage}
      theme={companyGrowthBadgeTheme}
      {...props}
    />
  )
}
