import { SpecterProducts } from "@prisma/client"
import { useQuery } from "@tanstack/react-query"
import { QuickSearchCountsResponse } from "~/routes/__protected/api/quick-search/counts"
import { QuickSearchResultsResponse } from "~/routes/__protected/api/quick-search/results"
import { cacheKeys } from "../cacheKeys"
import { sanitizeDomain } from "../sanitizeDomain"

export const useQuickSearchResults = ({
  term,
  tab,
}: {
  term: string
  tab: SpecterProducts
}) => {
  return useQuery<QuickSearchResultsResponse>({
    queryKey: cacheKeys.quickSearchResults(term, tab),
    async queryFn() {
      const searchParams = new URLSearchParams()
      searchParams.set("term", sanitizeDomain(term))
      searchParams.set("tab", tab)

      const req = await fetch(
        `/api/quick-search/results?${searchParams.toString()}`
      )
      return req.json()
    },
    staleTime: 60 * 60 * 1000,
  })
}

export const useQuickSearchCounts = (term: string) => {
  return useQuery<QuickSearchCountsResponse>({
    queryKey: cacheKeys.quickSearchCounts(term),
    async queryFn() {
      const searchParams = new URLSearchParams()
      searchParams.set("term", sanitizeDomain(term))

      const req = await fetch(
        `/api/quick-search/counts?${searchParams.toString()}`
      )
      return req.json()
    },
    enabled: term.length > 0,
  })
}
