import { Box, Button, Flex, Icon, Text, useDisclosure } from "@chakra-ui/react"
import { Card } from "~/components"
import { TabSubsectionTitle } from "~/components/CompanySignalDetailPage/Tabs/SubsectionTitle"
import { Tab } from "~/components/DetailPage/FullScreen"
import { TalentSignalHeadlineCard } from "~/components/TalentSignalDetail"
import {
  PeopleHighlight,
  PEOPLE_HIGHLIGHTS,
  PEOPLE_HIGHLIGHTS_PROPS,
} from "~/consts/signals"
import { Person } from "~/utils/db/peopleDBSchemas"
import { isNullish } from "~/utils/values"

export const About: Tab<"people">["TabContent"] = ({
  signal,
  onChangeTab = () => {},
}): JSX.Element => {
  return (
    <Flex direction="column" gap={6}>
      {signal.talent_data && (
        <TalentSignalHeadlineCard signal={signal.talent_data} />
      )}
      <AboutSection signal={signal} />
      <HighlightsSection signal={signal} onChangeTab={onChangeTab} />
    </Flex>
  )
}

const AboutSection = ({ signal }: { signal: Person }) => {
  const seeMoreDescription = useDisclosure()

  // Hide section if no data is available
  if (!signal.about) return null

  return (
    <Box as="section">
      <TabSubsectionTitle title={`About ${signal.first_name}`} />
      {signal.about ? (
        <>
          <Text
            fontSize="sm"
            color="gray.500"
            noOfLines={seeMoreDescription.isOpen ? undefined : 3}
            sx={{
              "@media print": {
                noOfLines: "unset",
              },
            }}
            whiteSpace="pre-wrap"
          >
            {signal.about}
          </Text>

          <Button
            variant="link"
            colorScheme="brand"
            size="xs"
            onClick={seeMoreDescription.onToggle}
            alignSelf="flex-start"
            sx={{
              "@media print": {
                display: "none",
              },
            }}
          >
            Show {seeMoreDescription.isOpen ? "Less" : "More"}
          </Button>
        </>
      ) : (
        <Text fontSize="sm" color="gray.400">
          No about section found for{" "}
          <Text as="strong" fontWeight="medium">
            {signal.first_name}
          </Text>
        </Text>
      )}
    </Box>
  )
}

export const sortPeopleHighlights = (highlights: PeopleHighlight[]) =>
  highlights.sort((a, b) => {
    const aIndex = PEOPLE_HIGHLIGHTS.indexOf(a)
    const bIndex = PEOPLE_HIGHLIGHTS.indexOf(b)
    return aIndex - bIndex
  })

const HighlightsSection = ({
  signal,
  onChangeTab,
}: {
  signal: Person
  onChangeTab: (idx: number) => void
}) => {
  const sortedHighlights = sortPeopleHighlights(
    (signal.highlights ?? []) as PeopleHighlight[]
  )

  // Hide section if no data is available
  if (!sortedHighlights.length) return null

  return (
    <Box as="section">
      <TabSubsectionTitle title="Highlights" />

      {sortedHighlights ? (
        <Flex gap={2} flexWrap="wrap">
          {/* <HighlightBox
            colorScheme="blue"
            icon={PiGraduationCapLight}
            label={`${signal.education_level ?? "Experience"}`}
            secondLine={`${
              signal.years_of_experience ?? "-"
            } years of experience`}
          /> */}
          {sortedHighlights.map((highlight) => {
            const { tab } = PEOPLE_HIGHLIGHTS_PROPS[highlight]
            return (
              <HighlightItem
                key={highlight}
                highlight={highlight as PeopleHighlight}
                onClick={!isNullish(tab) ? () => onChangeTab(tab) : undefined}
              />
            )
          })}
        </Flex>
      ) : (
        <Text fontSize="sm" color="gray.400">
          No highlights found for{" "}
          <Text as="strong" fontWeight="medium">
            {signal.first_name}
          </Text>
        </Text>
      )}
    </Box>
  )
}

export const HighlightItem = ({
  highlight,
  onClick,
}: {
  highlight: PeopleHighlight
  onClick?: () => void
}) => {
  const { label, secondLine, icon, colorScheme } =
    PEOPLE_HIGHLIGHTS_PROPS[highlight]

  return (
    <HighlightBox
      colorScheme={colorScheme}
      icon={icon}
      label={label}
      secondLine={secondLine}
      onClick={onClick}
    />
  )
}

const HighlightBox = ({
  colorScheme,
  icon,
  label,
  secondLine,
  onClick,
}: {
  colorScheme: string
  icon: React.ElementType
  label: string
  secondLine?: React.ReactNode
  onClick?: () => void
}) => {
  return (
    <Box
      p={1}
      rounded="xl"
      bgColor={`${colorScheme}.50`}
      borderColor={`${colorScheme}.100`}
      borderWidth={1}
      pos="relative"
    >
      {/* {isNew && <NotificationBadge value="New" color={`${colorScheme}.400`} />} */}

      <Text
        as="h2"
        color={`${colorScheme}.500`}
        fontSize={{ base: "xs", lg: "sm" }}
        whiteSpace="nowrap"
        px={2}
        py={1}
        fontWeight="semibold"
        display="inline-flex"
        alignItems="center"
        gap={1.5}
      >
        <Icon as={icon} /> {label}
      </Text>
      <Card
        display="flex"
        flexDirection="column"
        gap={1.5}
        p={1}
        px={2}
        borderStyle="dashed"
        bgColor={`${colorScheme}.50`}
        color={`${colorScheme}.500`}
        fontSize={{ base: "xx-small", lg: "xs" }}
        borderColor={`${colorScheme}.200`}
        // fontWeight="bold"
        onClick={onClick}
        cursor={onClick ? "pointer" : "default"}
        rounded="lg"
        justifyContent="center"
        sx={{ breakInside: "avoid" }}
      >
        {secondLine}
      </Card>
    </Box>
  )
}
