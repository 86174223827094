import {
  Box,
  Button,
  Center,
  Flex,
  Heading,
  IconButton,
  Input,
  Spacer,
  Text,
} from "@chakra-ui/react"
import { SpecterProducts } from "@prisma/client"
import { useNavigate } from "@remix-run/react"
import { useFormik } from "formik"
import { HiArrowRight } from "react-icons/hi"
import { z } from "zod"
import { toFormikValidationSchema } from "zod-formik-adapter"
import { AITag } from "~/components/AITag"
import { getSearchUrlFromProduct } from "~/components/UserSearchesPage/utils"
import { getProductSignalSearchParams } from "~/utils/hooks/useProductFilters"
import { useSafeSearchParams } from "~/utils/hooks/useSafeSearchParams"
import { ColoredTag } from "../ColoredTag"
import { getSortFromParams } from "../SignalSort"

const Suggestion = ({
  children,
  onClick,
}: {
  children: React.ReactNode
  onClick: () => void
}) => {
  return (
    <Button
      onClick={onClick}
      variant="dashed"
      whiteSpace="nowrap"
      textOverflow="ellipsis"
      overflow="hidden"
    >
      {children}
    </Button>
  )
}

const formSchema = z.object({
  query: z.string().min(1, "Please enter a query"),
})

const validationSchema = toFormikValidationSchema(formSchema)

export const SemanticSearchForm = () => {
  const navigate = useNavigate()
  const [searchParams] = useSafeSearchParams()
  const [field, direction] = getSortFromParams(searchParams)
  const initialValues = getProductSignalSearchParams(
    SpecterProducts.company,
    searchParams
  )

  const formik = useFormik({
    validationSchema,
    isInitialValid: false,
    initialValues: {
      query: initialValues.semanticSearch ?? "",
    },
    onSubmit: (values) => {
      navigate(
        getSearchUrlFromProduct(SpecterProducts.company) +
          "?" +
          new URLSearchParams({
            query: JSON.stringify({
              ...initialValues,
              semanticSearch: values.query,
            }),
            sort: JSON.stringify(
              field ? { [field]: direction } : { semanticScore: "desc" }
            ),
          }).toString()
      )
    },
  })

  return (
    <Center pos="relative" flex={1} overflow="hidden" zIndex={1} minH="600px">
      <Box
        pos="absolute"
        h={20}
        w="400px"
        maxW="full"
        top={0}
        left="50%"
        transform="translateX(-50%) translateY(-50%)"
        bgGradient="linear(to-l, brand.500, purple.500, red.500, orange.500)"
        filter="blur(48px)"
        rounded="100% / 100%"
        opacity={0.45}
        zIndex={-1}
      />
      <Box textAlign="center" w="660px" maxW="full" px={4}>
        <Center
          bgColor="white"
          rounded="lg"
          w={14}
          h={14}
          boxShadow="0 0 0 6px rgba(0, 0, 0, 0.03), 0 1px 2px 0 rgba(0, 0, 0, 0.1), inset 0 -2px 4px 1px rgba(0, 0, 0, 0.03)"
          fontSize="3xl"
          mx="auto"
          overflow="hidden"
          mb={3}
        >
          <AITag size="32px" />
        </Center>
        <Heading fontWeight="bold" fontSize="3xl" mb={1}>
          Smart Search Agent
        </Heading>
        <Text fontSize="sm" color="gray.500" mb={6} maxW="280px" mx="auto">
          Just describe the company you want to find - our AI handles the rest.
        </Text>

        <form onSubmit={formik.handleSubmit}>
          <Box shadow="sm" rounded="xl" borderWidth={1} borderColor="gray.200">
            <Input
              value={formik.values.query}
              onChange={formik.handleChange}
              name="query"
              size="md"
              variant="unstyled"
              placeholder="What kind of company or market are you looking for?"
              p={4}
              fontSize="sm"
              color="gray.800"
            />
            <Flex pb={2} px={2}>
              <Spacer />
              <IconButton
                type="submit"
                aria-label="Search"
                icon={<HiArrowRight />}
                variant="outline"
                rounded="full"
                size="sm"
                isDisabled={!formik.isValid}
              />
            </Flex>
          </Box>
        </form>

        <Flex flexWrap="wrap" justifyContent="center" gap={1.5} pt={3}>
          <Suggestion
            onClick={() => formik.setFieldValue("query", "Gen AI for video")}
          >
            Gen AI for video
          </Suggestion>
          <Suggestion
            onClick={() =>
              formik.setFieldValue("query", "Space technology startups")
            }
          >
            Space technology startups
          </Suggestion>
          <Suggestion
            onClick={() => formik.setFieldValue("query", "Spend management")}
          >
            Spend management
          </Suggestion>
          <Suggestion
            onClick={() => formik.setFieldValue("query", "AI for doctors")}
          >
            AI for doctors
          </Suggestion>
          <Suggestion
            onClick={() =>
              formik.setFieldValue("query", "Tools for developers")
            }
          >
            Tools for developers
          </Suggestion>
          <Suggestion
            onClick={() =>
              formik.setFieldValue("query", "Innovative approaches to farming")
            }
          >
            Innovative approaches to farming
          </Suggestion>
        </Flex>

        <Flex
          rounded="full"
          borderWidth={1}
          borderStyle="dashed"
          borderColor="gray.200"
          alignItems="center"
          p={0.5}
          display="inline-flex"
          mx="auto"
          bgColor="white"
          mt={6}
        >
          <ColoredTag color="brand" size="sm" rounded="full">
            Beta Feature
          </ColoredTag>
          <Text
            color="gray.400"
            fontSize="xx-small"
            fontWeight="semibold"
            px={2}
          >
            Help us shape it with your feedback
          </Text>
        </Flex>

        <Text
          maxW="580px"
          mx="auto"
          fontSize="xx-small"
          color="gray.400"
          mt={4}
        >
          Our AI currently focuses on semantic search for sectors and niches.
          We're actively developing additional features like supporting
          location, funding stage, and growth metrics. If you don't see what
          you're looking for, please share your feedback—we're improving every
          day!
        </Text>
      </Box>
    </Center>
  )
}
