import { Box, Flex, Heading, HStack, Tag, VStack } from "@chakra-ui/react"
import { GiSkills } from "react-icons/gi"
import { hasLength } from "~/utils/values"
import { BoxIcon } from "~/components/BoxIcon"

interface Props {
  skills?: string[]
}

export const TalentSignalSkills = ({ skills }: Props): JSX.Element | null => {
  if (!hasLength(skills)) {
    return null
  }

  return (
    <VStack alignItems="stretch">
      <Heading size="sm" as="h2">
        Skills
      </Heading>

      <HStack alignItems="start">
        <BoxIcon icon={GiSkills} />
        <Box alignSelf="center" py={1}>
          <Flex gap={1} flexWrap="wrap" mb={4}>
            {skills?.map((skill) => (
              <Tag key={skill} rounded="full">
                {skill}
              </Tag>
            ))}
          </Flex>
        </Box>
      </HStack>
    </VStack>
  )
}
