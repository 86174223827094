import { Heading, HStack, Text, VStack } from "@chakra-ui/react"
import { isNullish } from "~/utils/values"
import { BoxIcon } from "~/components/BoxIcon"
import { BsFillFilePersonFill } from "react-icons/bs"
import { currentYear } from "~/utils/datetime"

interface Props {
  birthYear?: number | null
}

export const TalentSignalAge = ({ birthYear }: Props): JSX.Element | null => {
  if (isNullish(birthYear) || birthYear === 0) {
    return null
  }

  const age = currentYear - birthYear

  return (
    <VStack alignItems="stretch">
      <Heading size="sm" as="h2">
        Age
      </Heading>
      <HStack alignItems="center">
        <BoxIcon icon={BsFillFilePersonFill} />
        <Text fontSize="sm">{age} years old</Text>
      </HStack>
    </VStack>
  )
}
