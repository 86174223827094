import { Heading, Text, VStack } from "@chakra-ui/react"

export const TalentPastSignals = (): JSX.Element | null => {
  return (
    <VStack alignItems="stretch">
      <Heading as="h2" size="sm">
        Past Signals
      </Heading>
      <Text fontSize="xs" color="gray.400">
        Coming soon...
      </Text>
    </VStack>
  )
}
