import { Box } from "@chakra-ui/react"

export const IconCompany = (props: any) => {
  return (
    <Box as="svg" viewBox="0 0 32 32" {...props}>
      <rect fill="#586eff" width="32" height="32" rx="9" ry="9" />
      <path
        fill="white"
        d="M15,17v5c0,2.76-2.24,5-5,5h0c-2.76,0-5-2.24-5-5h0c0-2.76,2.24-5,5-5h5ZM17,22c0,2.76,2.24,5,5,5h0c2.76,0,5-2.24,5-5h0c0-2.76-2.24-5-5-5h-5v5ZM22,15c2.76,0,5-2.24,5-5h0c0-2.76-2.24-5-5-5h0c-2.76,0-5,2.24-5,5v5h5ZM15,10c0-2.76-2.24-5-5-5h0c-2.76,0-5,2.24-5,5h0c0,2.76,2.24,5,5,5h5v-5Z"
      />
    </Box>
  )
}
