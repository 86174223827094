import { Box, Icon } from "@chakra-ui/react"
import { useLocation } from "@remix-run/react"
import { createContext, ReactNode, useContext } from "react"
import { HiChevronDown } from "react-icons/hi"

interface Props {
  openPaths: string[]
  children: ReactNode
}

const AccordionContext = createContext({ isOpen: false })

export const SidebarAccordion = ({ children, openPaths }: Props) => {
  const location = useLocation()
  const isOpen =
    openPaths?.some((path) => location.pathname.includes(path)) ?? false

  return (
    <AccordionContext.Provider value={{ isOpen }}>
      <Box>{children}</Box>
    </AccordionContext.Provider>
  )
}

export const useSidebarAccordion = () => {
  return useContext(AccordionContext)
}

type SidebarAccordionPanelProps = {
  children: ReactNode
}

export const SidebarAccordionPanel = ({
  children,
}: SidebarAccordionPanelProps) => {
  const { isOpen } = useSidebarAccordion()

  return (
    <Box
      transition="all .3s linear"
      style={{
        maxHeight: isOpen ? "240px" : "0px",
        // transform: `scaleY(${isOpen ? 1 : 0})`,
        opacity: isOpen ? 1 : 0,
      }}
      overflow="hidden"
      transformOrigin="top"
      pos="relative"
      flex="none"
    >
      {children}
    </Box>
  )
}

export const SidebarAccordionLine = () => {
  return (
    <Box pos="absolute" w="1px" bgColor="gray.200" top={0} h="full" left={3} />
  )
}

export const SidebarAccordionIcon = () => {
  const { isOpen } = useSidebarAccordion()

  return (
    <Icon
      aria-label="Open Menu"
      as={HiChevronDown}
      transition="all .3s"
      transform={`rotate(${isOpen ? "0deg" : "-90deg"})`}
    />
  )
}
