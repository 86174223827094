import {
  Button,
  ButtonGroup,
  Flex,
  Grid,
  Text,
  useTheme,
} from "@chakra-ui/react"
import { SpecterProducts } from "@prisma/client"
import { format, parse, sub } from "date-fns"
import numeral from "numeral"
import { useMemo, useState } from "react"
import {
  Area,
  Bar,
  CartesianGrid,
  ComposedChart,
  DotProps,
  LabelList,
  LabelListProps,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from "recharts"
import {
  NameType,
  ValueType,
} from "recharts/types/component/DefaultTooltipContent"
import {
  CompanyHistoricalMetrics,
  CompanyHistoricalMetricsKey,
} from "~/utils/db/queries/company/types"
import { isNullish } from "~/utils/values"
import { GRAPH_COLORS } from "."
import { TooltipSimplifiedProps } from "../ChartSimplified"

export interface ChartOverTimeHistoricalsProps {
  reversed?: boolean
  data: CompanyHistoricalMetrics[]
  field: CompanyHistoricalMetricsKey
  yAxisValueFormat?: string
  label: string
  product?: SpecterProducts
}

const CHART_PERIODS = ["6 Months", "YTD", "1 Year", "2 Years"] as const

export const ChartOverTimeHistoricals = ({
  reversed = false,
  data,
  field,
  yAxisValueFormat = "0.[00]a",
  label = "Value",
  product = SpecterProducts.company,
}: ChartOverTimeHistoricalsProps): JSX.Element => {
  const theme = useTheme()

  const chartColor = GRAPH_COLORS[product] ?? theme.colors.blue[500]

  const [selectedPeriod, setSelectedPeriod] =
    useState<(typeof CHART_PERIODS)[number]>("6 Months")

  const lastDataMonth = data[data.length - 1].month

  const latestDataPoint = useMemo(
    () => parse(lastDataMonth, "yyyy-MM-dd", new Date()),
    [lastDataMonth]
  )
  const latestMonthInData = Number(latestDataPoint.getMonth())

  const monthsFrame = {
    "6 Months": 6,
    "1 Year": 12,
    "2 Years": 24,
    YTD: latestMonthInData,
  }[selectedPeriod]

  const periodData = useMemo(() => {
    if (!monthsFrame) return data

    const baseDataArray = Array.from({ length: monthsFrame + 1 }, (_, i) => ({
      month: format(
        sub(latestDataPoint, { months: monthsFrame - i }),
        "yyyy-MM-dd"
      ),
    }))

    return baseDataArray.map((item) => ({
      ...item,
      ...(data.find((d) => d.month === item.month) ?? {}),
    }))
  }, [data, latestDataPoint, monthsFrame])

  const yearsColSpan = useMemo(
    () =>
      periodData
        .map((item) => item.month.split("-")[0])
        .reduce((acc, curr) => {
          acc[curr] =
            (acc[curr] ??
              // Start with -1 to account for the latest year
              (Number(curr) == latestDataPoint.getFullYear() ? -1 : 0)) + 1
          return acc
        }, {} as Record<string, number>),
    [latestDataPoint, periodData]
  )

  const CustomLabelProps: LabelListProps<any> = {
    position: "insideTop",
    fill: "white",
    fontSize: 10,
    fontWeight: "bold",
    formatter: (value: number) =>
      value ? numeral(value).format("0.[00]a") : "",
  }

  return (
    <Flex direction="column" gap={2} position="relative">
      <ButtonGroup size="xs" isAttached alignSelf="flex-end">
        {CHART_PERIODS.map((period) => (
          <Button
            key={period}
            onClick={() => setSelectedPeriod(period)}
            colorScheme={selectedPeriod === period ? "brand" : "gray"}
          >
            {period}
          </Button>
        ))}
      </ButtonGroup>
      <ResponsiveContainer width="100%" height={250}>
        <ComposedChart
          width={500}
          height={300}
          data={periodData}
          margin={{
            right: 15,
          }}
        >
          <defs>
            <linearGradient id="overTimeGrad" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor={chartColor} stopOpacity={0.5} />
              <stop offset="100%" stopColor={chartColor} stopOpacity={0} />
            </linearGradient>
          </defs>
          <CartesianGrid strokeDasharray="6 6" stroke="#00000015" />
          <YAxis
            reversed={reversed}
            dataKey={field}
            fontSize="12px"
            width={80}
            allowDecimals={false}
            tickFormatter={(value) => numeral(value).format(yAxisValueFormat)}
            interval="preserveStartEnd"
            domain={[
              (dataMin: number) => Math.min(0, dataMin),
              (dataMax: number) => {
                for (let i = 10; i <= 10e7; i *= 10) {
                  if (dataMax < 5 * i) {
                    return Math.ceil(dataMax / i) * i
                  }
                }

                return dataMax
              },
            ]}
          />
          <XAxis
            dataKey="month"
            fontSize="12px"
            scale="point"
            tickFormatter={(value) => {
              const month = parse(value, "yyyy-MM-dd", new Date())
              return format(month, "MMM")
            }}
          />

          <Tooltip
            content={CustomTooltip}
            {...TooltipSimplifiedProps(theme, yAxisValueFormat, label)}
            wrapperStyle={{
              visibility: "visible",
            }}
          />
          <Area
            type="monotone"
            dataKey={field}
            name={label}
            stroke={chartColor}
            fill="url(#overTimeGrad)"
            connectNulls
            strokeWidth={2}
            activeDot={(props: DotProps) => (
              <CustomDot color={chartColor} {...props} />
            )}
          />

          {field === "employee_count" && (
            <>
              <Bar
                dataKey="leaversCountScaled"
                stackId="bars"
                fill="#f76f6f"
                barSize={30}
                name="Notable Leavers"
              >
                <LabelList dataKey="leaversCount" {...CustomLabelProps} />
              </Bar>

              <Bar
                dataKey="promotionsCountScaled"
                stackId="bars"
                fill="#f6c745"
                barSize={30}
                name="Notable Promotions"
              >
                <LabelList dataKey="promotionsCount" {...CustomLabelProps} />
              </Bar>

              <Bar
                dataKey="hiresCountScaled"
                stackId="bars"
                fill="#82ca9d"
                barSize={30}
                name="Notable Hires"
              >
                <LabelList dataKey="hiresCount" {...CustomLabelProps} />
              </Bar>
            </>
          )}
        </ComposedChart>
      </ResponsiveContainer>
      <Grid
        templateColumns={Object.values(yearsColSpan)
          .map((colSpan) => `${colSpan}fr`)
          .join(" ")}
        justifyItems="stretch"
        position="absolute"
        bottom={"-10px"}
        left={"80px"}
        right={"15px"}
      >
        {Object.keys(yearsColSpan).map((year) => (
          <Text
            key={year}
            fontSize="12px"
            color="#666"
            pl={1}
            borderLeft="1px solid"
            borderBottom="1px solid"
            borderColor="gray.200"
          >
            {year}
          </Text>
        ))}
      </Grid>
    </Flex>
  )
}

const CustomTooltip = <TValue extends ValueType, TName extends NameType>({
  active,
  payload,
  label,
  ...props
}: TooltipProps<TValue, TName>) => {
  if (active && payload && payload.length) {
    const { contentStyle, itemStyle, labelStyle, labelFormatter, formatter } =
      props

    const [area, ...bars] = payload

    const deltaProps = {
      positive: {
        color: "green",
        icon: "▲",
        fontSize: "12px",
      },
      negative: {
        color: "red",
        icon: "▼",
        fontSize: "10px",
      },
    }

    const signType =
      area.payload.delta > 0
        ? "positive"
        : area.payload.delta < 0
        ? "negative"
        : null

    return (
      <Flex direction="column" p={3} sx={contentStyle} gap={2}>
        <Text sx={labelStyle}>{labelFormatter?.(label, payload)}</Text>
        {area && (
          <>
            <Text sx={itemStyle}>
              {area.name}:{" "}
              {formatter?.(
                area.payload[String(area.dataKey) ?? ""],
                area.name!,
                area,
                0,
                payload
              )}
            </Text>
            {!isNullish(area.payload.delta) && (
              <Text sx={itemStyle}>
                Δ {area.name}:{" "}
                {signType && (
                  <Text
                    as="span"
                    color={deltaProps[signType].color}
                    fontSize={deltaProps[signType].fontSize}
                  >
                    {deltaProps[signType].icon}
                  </Text>
                )}
                {area.payload.delta}
              </Text>
            )}
          </>
        )}
        {bars.map((p) => (
          <Text key={p.name} sx={itemStyle}>
            {p.name}:{" "}
            {p.payload[String(p.dataKey)?.replace("Scaled", "") ?? ""]}
          </Text>
        ))}
      </Flex>
    )
  }

  return null
}

const CustomDot = (props: any) => {
  const {
    cx,
    cy,
    value: [_, value],
    color,
  } = props

  if (!value) return null

  return (
    <>
      <circle cx={cx} cy={cy} r={5} strokeWidth={3} fill={color} />
      <circle cx={cx} cy={cy} r={10} strokeWidth={3} fill={color}>
        <animate
          attributeName="r"
          values="5;13"
          dur="1.6s"
          repeatCount="indefinite"
          fill="freeze"
        />
        <animate
          attributeName="opacity"
          values="0.6;0"
          dur="1.6s"
          repeatCount="indefinite"
          fill="freeze"
        />
      </circle>
    </>
  )
}
