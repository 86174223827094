import { Box } from "@chakra-ui/react"

export const IconTransaction = (props: any) => {
  return (
    <Box as="svg" viewBox="0 0 32 32" {...props}>
      <rect fill="#586eff" width="32" height="32" rx="9" ry="9" />
      <path
        fill="white"
        d="M11.8,27c-.34,0-.68-.13-.93-.39l-5.19-5.19s0,0-.01-.01h0s0,0,0,0h0c-.12-.12-.21-.27-.27-.42-.06-.15-.1-.31-.1-.48h0v-.04h0c0-.17.04-.34.1-.48.06-.16.16-.3.29-.43l5.19-5.19c.52-.52,1.35-.52,1.87,0s.52,1.35,0,1.87l-2.93,2.93h10.02c.73,0,1.32.59,1.32,1.32s-.59,1.32-1.32,1.32h-10.02l2.93,2.93c.52.52.52,1.35,0,1.87-.26.26-.6.39-.93.39ZM20.2,18.02c-.34,0-.68-.13-.93-.39-.52-.52-.52-1.35,0-1.87l2.93-2.93h-10.02c-.73,0-1.32-.59-1.32-1.32s.59-1.32,1.32-1.32h10.02l-2.93-2.93c-.52-.52-.52-1.35,0-1.87.52-.52,1.35-.52,1.87,0l5.19,5.19c.13.13.22.27.29.43.06.16.1.33.1.51s-.04.35-.1.51c-.06.15-.16.3-.29.43l-5.19,5.19c-.26.26-.6.39-.93.39Z"
      />
    </Box>
  )
}
