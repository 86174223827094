import { Divider, Flex, Heading, SimpleGrid, Tag } from "@chakra-ui/react"
import { SpecterProducts } from "@prisma/client"
import { FormikProps } from "formik"
import { NEW_FEATURED_FILTER_SUB_SECTIONS } from "~/components"
import {
  FiltersSectionSchema,
  FiltersTabSchema,
  SignalFilters,
} from "../schemas"
import { AdvancedFilterItem, Dot } from "./AdvancedFilterItem"

type AdvancedFiltersProps<P extends SpecterProducts, Schema> = {
  title?: string | JSX.Element
  product: P
  formik: FormikProps<SignalFilters[P]>
  schema: Schema
}

export const AdvancedFilterSection = <
  P extends SpecterProducts,
  Schema extends FiltersSectionSchema
>({
  product,
  formik,
  title,
  schema,
}: AdvancedFiltersProps<P, Schema>) => {
  const isDirty = Object.keys(schema.shape).some(
    (key) => formik.values[key as keyof typeof formik.values] !== undefined
  )

  const isNew = NEW_FEATURED_FILTER_SUB_SECTIONS.includes(
    title?.toString() ?? ""
  )
  const entries = Object.entries(schema.shape)

  return (
    <Flex direction="column" gap={2}>
      <Flex gap={3} alignItems="center" overflow="hidden">
        <Heading
          fontSize="md"
          as="h3"
          fontWeight="semibold"
          display="flex"
          alignItems="center"
        >
          {title}
          {isNew && <Tag colorScheme="brand">New</Tag>}
          {isDirty && <Dot />}
        </Heading>
        <Divider flex={1} />
      </Flex>

      <SimpleGrid columns={2} gap={4} columnGap={6}>
        {entries.map(([innerField, innerSchema]) => (
          <AdvancedFilterItem
            key={innerField}
            schema={innerSchema}
            value={formik.values}
            onChange={(values) => formik.setValues(values)}
            product={product}
            field={innerField}
            onBlur={formik.handleBlur}
          />
        ))}
      </SimpleGrid>
    </Flex>
  )
}

export const AdvancedFilterTab = <
  P extends SpecterProducts,
  Schema extends FiltersTabSchema
>({
  product,
  formik,
  schema,
}: AdvancedFiltersProps<P, Schema>) => {
  return (
    <Flex direction="column" gap={8}>
      {Object.entries(schema.shape).map(([innerField, innerSchema]) => (
        <AdvancedFilterSection
          key={innerField}
          title={innerField}
          product={product}
          formik={formik}
          schema={innerSchema}
        />
      ))}
    </Flex>
  )
}
