import {
  Alert,
  AlertIcon,
  AlertTitle,
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Center,
  Flex,
  Grid,
  GridItem,
  Heading,
  Icon,
  SimpleGrid,
  Spacer,
  Spinner,
  Tag,
  Text,
  VStack,
} from "@chakra-ui/react"
import { SpecterProducts } from "@prisma/client"
import {
  useLoaderData,
  useLocation,
  useNavigate,
  useParams,
} from "@remix-run/react"
import { DehydratedState, useHydrate } from "@tanstack/react-query"
import { useEffect, useRef } from "react"
import { HiArrowLeft } from "react-icons/hi"
import { allTalentSignalStatus } from "~/consts/talentSignals"
import { getSignalLocation } from "~/utils/getSignalLocation"
import { useBgColor } from "~/utils/hooks/useBgColor"
import { useTalentSignalByIDQuery } from "~/utils/hooks/useTalentSignalByIDQuery"
import invariant from "~/utils/invariant"
import { isOneOf } from "~/utils/isOneOf"
import { getSignalDate } from "~/utils/signalDate"

import { motion } from "framer-motion"
import {
  ActionMenuIcons,
  ButtonDivider,
  ButtonEmail,
  ButtonGithub,
  ButtonLinkedin,
  ButtonTwitter,
  CopyLinkButton,
  PRODUCT_ICONS,
  showDividerTalentSignal,
  SignalStatusTalent,
  TalentSignalTitle,
} from "~/components"
import { Card } from "~/components/Card"
import { GetTalentSignalByID } from "~/utils/db/getTalentSignalByID"
import { isDateValid } from "~/utils/isDateValid"
import { hasLength } from "~/utils/values"
import { mapProductsToRouteName } from "../Filters/schemas"
import { TalentSignalAge } from "./Age"
import { TalentSignalEducation } from "./Education"
import { TalentSignalLanguages } from "./Languages"
import { TalentLatestSignal } from "./LatestSignal"
import { TalentSignalNewPositionCard } from "./NewPositionCard"
import { TalentSignalPastPositionsCard } from "./PastPositionsCard"
import { TalentPastSignals } from "./PastSignals"
import { TalentSignalSkills } from "./Skills"
import { TalentSignalYearsOfExperience } from "./YearsOfExperience"

export const TalentSignalDetailPage = (): JSX.Element => {
  const bgColor = useBgColor()
  const dehydratedState = useLoaderData<DehydratedState>()
  useHydrate(dehydratedState)
  const portalRef = useRef(null)
  const location = useLocation()
  const navigate = useNavigate()

  const params = useParams()

  invariant(params.id, "Missing id param")

  const talentSignalQuery = useTalentSignalByIDQuery(params.id)

  useEffect(() => {
    if (!talentSignalQuery.data) return

    document.title = `${talentSignalQuery.data.signal.Name} | Specter`
  }, [talentSignalQuery.data])

  if (talentSignalQuery.isLoading) {
    return (
      <Center minH="100vh">
        <Spinner />
      </Center>
    )
  }

  if (talentSignalQuery.isError) {
    return (
      <Alert status="error">
        <AlertIcon />
        <AlertTitle>Unable to load talent signal</AlertTitle>
      </Alert>
    )
  }

  const { signal } = talentSignalQuery.data

  const goBack = () => {
    if (global.window.history.length > 2) {
      global.window.history.back()
      return
    }

    navigate(`../${location.search}`)
  }

  const product = SpecterProducts.talent

  return (
    <Box as="main">
      <Flex
        px={6}
        py={3}
        position="sticky"
        top={0}
        zIndex={2}
        bg={bgColor}
        borderBottomWidth={1}
        borderColor="gray.100"
      >
        {goBack && (
          <Button
            leftIcon={<HiArrowLeft />}
            onClick={goBack}
            variant="outline"
            size="xs"
          >
            Back
          </Button>
        )}

        <Spacer />
        <ButtonGroup size="xs" variant="outline">
          <CopyLinkButton
            // "/f" means view full page
            url={`/signals/${mapProductsToRouteName(product)}/feed/${
              signal.id
            }/f`}
          />
        </ButtonGroup>
      </Flex>

      <motion.div>
        <Box p={6} position="relative" zIndex={1}>
          <Flex
            gap={4}
            flexDir={{ base: "column", md: "row" }}
            w="full"
            overflow="hidden"
          >
            <Flex gap={4} flexShrink={0} overflow="hidden">
              <Avatar
                src={signal.ProfilePicture ?? undefined}
                w={36}
                h={36}
                borderRadius="xl"
              />
              <Flex direction={"column"} pt={1}>
                <Text
                  color="gray.400"
                  display="inline-flex"
                  alignItems="center"
                  fontSize="xx-small"
                  gap={1}
                >
                  <Icon as={PRODUCT_ICONS[SpecterProducts.talent]} />
                  Talent
                </Text>
                <Heading as="h1" fontSize="2xl">
                  {signal.Name}
                </Heading>

                <Text fontSize="sm">
                  {getSignalLocation({
                    location: signal.Location,
                    region: signal.Region,
                  })}
                </Text>

                <Flex gap={1} mt={2} mb={3}>
                  {signal.Department && (
                    <Tag rounded="full">{signal.Department}</Tag>
                  )}

                  {signal.SerialFounder === "Yes" && (
                    <Tag bgColor="#00A8A8" rounded="full" variant="solid">
                      Serial Founder
                    </Tag>
                  )}
                </Flex>

                <ButtonGroup
                  pt={1}
                  variant="outline"
                  spacing={1}
                  alignItems="stretch"
                >
                  <ButtonLinkedin linkedinUrl={signal.LinkedInURLTalent} />
                  <ButtonTwitter
                    twitterUrl={
                      signal.TwitterURLTalent ||
                      signal.personalDetail?.twitterUrl
                    }
                  />
                  <ButtonGithub githubUrl={signal.personalDetail?.githubUrl} />
                  <ButtonEmail email={signal.personalDetail?.email} />

                  {showDividerTalentSignal(signal) && <ButtonDivider />}

                  <ActionMenuIcons
                    product={SpecterProducts.talent}
                    signal={signal}
                    containerRef={portalRef}
                    stretchButtons={false}
                  />
                </ButtonGroup>
              </Flex>
            </Flex>
          </Flex>
        </Box>
      </motion.div>

      <Box p={6}>
        <Box maxW="container.md">
          <VStack alignItems="stretch" spacing={4}>
            <TalentSignalHeadlineCard signal={signal} />

            <SimpleGrid columns={{ base: 1, md: 2 }} spacing={5}>
              <TalentSignalNewPositionCard talentSignal={signal} />
              <TalentSignalPastPositionsCard talentSignal={signal} />
            </SimpleGrid>

            <Card>
              <VStack alignItems="stretch" spacing={4}>
                <Grid
                  gap={{ base: 4, md: 6 }}
                  templateColumns={{ base: "1fr", md: "1fr 1fr" }}
                >
                  <GridItem>
                    <TalentSignalEducation talentSignal={signal} />
                  </GridItem>
                  <GridItem>
                    <TalentSignalYearsOfExperience
                      seniority={signal.seniority}
                      yearsOfExperience={signal.YearsOfExperience}
                    />
                  </GridItem>

                  {signal.Languages && (
                    <GridItem>
                      <TalentSignalLanguages languages={signal.Languages} />
                    </GridItem>
                  )}

                  {signal.personalDetail?.birthYear && (
                    <GridItem>
                      <TalentSignalAge
                        birthYear={signal.personalDetail?.birthYear}
                      />
                    </GridItem>
                  )}

                  {hasLength(signal?.personalDetail?.skills) && (
                    <GridItem colSpan={2}>
                      <TalentSignalSkills
                        skills={signal?.personalDetail?.skills}
                      />
                    </GridItem>
                  )}
                </Grid>
              </VStack>
            </Card>

            <Box>
              <Card
                display="flex"
                flexDirection="column"
                gap={{ base: 4, md: 6 }}
              >
                <TalentLatestSignal talentSignal={signal} />
                <TalentPastSignals />
              </Card>
            </Box>
          </VStack>
        </Box>
      </Box>

      <div ref={portalRef} />
    </Box>
  )
}

export const TalentSignalHeadlineCard = ({
  signal,
}: {
  signal: GetTalentSignalByID
}) => {
  return (
    <Card borderWidth={1} borderColor="brand.500">
      <Flex alignItems="start">
        <Box mr="auto">
          <Flex gap={1} flexWrap="wrap">
            {signal.SignalType && <Tag rounded="full">{signal.SignalType}</Tag>}

            {signal.SignalScore && (
              <Tag rounded="full">Score: {signal.SignalScore}/10</Tag>
            )}
          </Flex>
          <Heading as="h1" fontSize="md" my={2} lineHeight={1.4}>
            <TalentSignalTitle talentSignal={signal} />
          </Heading>

          {isDateValid(signal.SignalDate) && (
            <Text fontSize="xs" color="gray.500" fontWeight="semibold">
              {getSignalDate(signal.SignalDate)}
            </Text>
          )}
        </Box>

        <Box textAlign="right">
          {isOneOf(signal.SignalStatus, allTalentSignalStatus) && (
            <SignalStatusTalent signalStatus={signal.SignalStatus} />
          )}

          {signal.OutOfStealthAdvantage !== null && (
            <Text color="gray.300" fontSize="xs" fontWeight="semibold">
              {signal.OutOfStealthAdvantage} ADV
            </Text>
          )}
        </Box>
      </Flex>
    </Card>
  )
}
