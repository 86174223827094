import { Box, Heading, HStack, Text, VStack } from "@chakra-ui/react"
import { HiShieldCheck } from "react-icons/hi"
import { BoxIcon } from "~/components/BoxIcon"
import { FaSuitcase } from "react-icons/fa"
import { formatSeniority } from "~/utils/string/formatters"
import { Seniority } from "@prisma/client"

interface Props {
  seniority?: Seniority | null
  yearsOfExperience?: string | null
}

export const TalentSignalYearsOfExperience = ({
  seniority,
  yearsOfExperience,
}: Props): JSX.Element | null => {
  if (!yearsOfExperience && !seniority) {
    return null
  }

  return (
    <VStack alignItems="stretch">
      <Heading as="h2" size="sm">
        Experience
      </Heading>
      {seniority && (
        <HStack alignItems="start">
          <BoxIcon icon={FaSuitcase} />
          <Box alignSelf="center" py={1}>
            <Text fontSize="sm">{formatSeniority(seniority)}</Text>
          </Box>
        </HStack>
      )}
      {yearsOfExperience && (
        <HStack alignItems="start">
          <BoxIcon icon={HiShieldCheck} />
          <Box alignSelf="center" py={1}>
            <Text fontSize="sm">{yearsOfExperience} of experience</Text>
          </Box>
        </HStack>
      )}
    </VStack>
  )
}
