import {
  Box,
  Button,
  Flex,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
  Text,
} from "@chakra-ui/react"
import { SpecterProducts } from "@prisma/client"
import { PiSortAscendingBold, PiSortDescendingBold } from "react-icons/pi"
import { SortDirection } from "~/utils/db/queries/types"
import { useSafeSearchParams } from "~/utils/hooks/useSafeSearchParams"
import { getSignalFieldTitleString } from "~/utils/signal"
import { updateSearchParams } from "~/utils/updateSearchParams"
import { ReactSelect } from "../ReactSelect"
import { signalSortFields } from "../Table/TableHeader"

interface Props<T extends readonly string[]> {
  fields: T
}

export function SignalSort<T extends readonly string[]>({
  fields,
}: Props<T>): JSX.Element {
  const [searchParams, setSearchParams] = useSafeSearchParams()

  const [field, direction] = getSortFromParams(searchParams)

  const changeSort = (newSortBy: string | null) => {
    const newSearchParams = updateSearchParams(searchParams, {
      sort: { ...(newSortBy ? { [newSortBy]: direction ?? "desc" } : {}) },
    })

    setSearchParams(newSearchParams)
  }

  const changeDirection = (newDirection: SortDirection) => {
    if (!field) return

    const newSearchParams = updateSearchParams(searchParams, {
      sort: {
        [field]: newDirection,
      },
    })

    setSearchParams(newSearchParams)
  }

  return (
    <Flex flexDir="column" gap={2} p={2}>
      <Flex
        rounded="lg"
        gap={0.5}
        borderWidth={1}
        p={0.5}
        shadow="sm"
        alignItems="center"
      >
        <Button
          flex={1}
          colorScheme={direction === "desc" ? "brand" : "gray"}
          variant={direction === "desc" ? "solid" : "ghost"}
          size="xs"
          onClick={() => changeDirection("desc")}
        >
          Descending
        </Button>
        <Box w="1px" h={3} bgColor="gray.200" />
        <Button
          flex={1}
          colorScheme={direction === "asc" ? "brand" : "gray"}
          variant={direction === "asc" ? "solid" : "ghost"}
          size="xs"
          onClick={() => changeDirection("asc")}
        >
          Ascending
        </Button>
      </Flex>
      <Box w="full">
        <ReactSelect
          placeholder="Sort by"
          isSearchable
          isClearable
          options={fields?.map((field) => ({
            label: getSignalFieldTitleString(field),
            value: field,
          }))}
          value={
            field
              ? { label: getSignalFieldTitleString(field), value: field }
              : null
          }
          onChange={(option) => {
            if (option?.value) {
              changeSort(option.value)
            } else {
              changeSort(null)
            }
          }}
        />
      </Box>
    </Flex>
  )
}

type SignalSortMenuProps<P extends SpecterProducts> = {
  product: P
  onOpen?: () => void
}
export const SignalSortMenu = <P extends SpecterProducts>({
  product,
  onOpen,
}: SignalSortMenuProps<P>) => {
  const [searchParams] = useSafeSearchParams()
  const [field, direction] = getSortFromParams(searchParams)
  const sortOptions = signalSortFields(product) as string[]
  const fieldTitle = field ? getSignalFieldTitleString(field) : undefined

  if (!sortOptions.length) return null

  return (
    <Popover isLazy placement="top-start">
      <PopoverTrigger>
        <Button
          flex="none"
          variant="dashed"
          leftIcon={
            direction === "desc" ? (
              <PiSortAscendingBold />
            ) : (
              <PiSortDescendingBold />
            )
          }
          onClick={onOpen}
          pos="relative"
        >
          {field ? (
            <>
              Sorted by
              <Text as="span" color="brand.500" ml={1}>
                {fieldTitle}
              </Text>
            </>
          ) : (
            "Sort"
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent
        mt={-0.5}
        width="280px"
        borderWidth={0}
        bgColor="transparent"
      >
        <Box
          shadow="lg"
          p={1}
          rounded="xl"
          borderWidth={1}
          bgColor="white"
          position="relative"
          zIndex={-1}
        >
          <PopoverArrow />
          <Box rounded="xl" overflow="hidden">
            <SignalSort fields={sortOptions} />
          </Box>
        </Box>
      </PopoverContent>
    </Popover>
  )
}

export const getSortFromParams = (searchParams: URLSearchParams) => {
  return (Object.entries(JSON.parse(searchParams.get("sort") ?? "{}"))[0] ??
    []) as [string, SortDirection]
}
