import { Tag } from "@chakra-ui/react"

import { TagProps } from "@chakra-ui/react"
import { forwardRef, ReactNode } from "react"

const sizeProps = (size: string) =>
  ({
    xs: { fontSize: "8px", outlineOffset: "none", px: 1, py: 0.5, minH: 0 },
    sm: { fontSize: "xx-small", outlineOffset: "none" },
    md: { fontSize: "xs", outlineOffset: "2px" },
    lg: { fontSize: "sm", outlineOffset: "4px" },
  }[size])

type ColoredTagProps = {
  color?: string
  children: ReactNode
  size?: "xs" | "sm" | "md" | "lg"
} & Omit<TagProps, "color">

export const ColoredTag = forwardRef<HTMLDivElement, ColoredTagProps>(
  ({ color, children, size = "md", ...props }, ref) => {
    const extraSizeProps = sizeProps(size)
    const [baseColor] = color?.split(".") ?? []

    return (
      <Tag
        ref={ref}
        borderRadius="full"
        variant="outline"
        color={baseColor + ".500"}
        borderWidth={1}
        borderColor={baseColor + ".200"}
        boxShadow="none"
        fontWeight="medium"
        overflow="hidden"
        whiteSpace="nowrap"
        bgColor={baseColor + ".50"}
        {...extraSizeProps}
        {...props}
      >
        {children}
      </Tag>
    )
  }
)

ColoredTag.displayName = "ColoredTag"
