import { SpecterProducts } from "@prisma/client"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import invariant from "~/utils/invariant"

import { ProductDetailItem, ProductItem } from "~/components"
import { mapProductsToRouteName } from "~/components/Filters/schemas"
import { FAVOURITES_LIST_NAME } from "~/consts/lists"
import { cacheKeys } from "../cacheKeys"
import { GetTalentSignalByID } from "../db/getTalentSignalByID"
import { useAnalytics } from "./useAnalytics"
import { useEnv } from "./useEnv"

interface Result {
  isFavourite: boolean
}

export const useSignalFavourite = <P extends SpecterProducts>(
  signal: ProductItem<P> | ProductDetailItem<P>,
  product: P
) => {
  const queryClient = useQueryClient()
  const analytics = useAnalytics()
  const env = useEnv()

  const favouriteQuery = useQuery<Result>(
    cacheKeys.signalFavourite(signal.id),
    async () => {
      const res = await fetch(`/api/favourites/${product}/${signal.id}`)

      invariant(res.ok, "Failed to load signal favourite status")

      return await res.json()
    },
    {
      refetchOnMount: false,
      initialData: () => {
        if ("isFavourite" in signal) {
          return {
            isFavourite: signal.isFavourite,
          }
        }

        const detailSignal = signal as GetTalentSignalByID

        if (detailSignal.lists) {
          return {
            isFavourite: detailSignal.lists.some(
              ({ list }) => list.name === FAVOURITES_LIST_NAME
            ),
          }
        }

        return { isFavourite: false }
      },
    }
  )

  const favouriteMutation = useMutation<Result>(
    async () => {
      const res = await fetch(`/api/favourites/${product}/${signal.id}`, {
        method: "POST",
      })

      invariant(res.ok, "Failed to favourite signal")

      return await res.json()
    },
    {
      async onSuccess() {
        await queryClient.refetchQueries(cacheKeys.signalFavourite(signal.id))
        await queryClient.invalidateQueries(cacheKeys.actionOptions(product))

        const signalURL = `${env.APP_URL}/signals/${mapProductsToRouteName(
          product
        )}/feed/${signal.id}${location.search}`

        if (!favouriteQuery.data?.isFavourite)
          analytics.track("Add to Favourites", {
            product,
            signalURL,
            signalId: signal.id,
          })
      },
    }
  )

  return {
    isFavourite: favouriteQuery.data?.isFavourite ?? false,
    toggleFavourite: () => favouriteMutation.mutate(),
    isLoading: favouriteMutation.isLoading,
  }
}

export const useSignalFavourites = <P extends SpecterProducts>(
  signals: ProductItem<P>[],
  product: P
) => {
  const queryClient = useQueryClient()
  const analytics = useAnalytics()

  const signalIds = signals.map((signal) => signal.id)

  const isFavouriteEach = signalIds.map((id) =>
    queryClient.getQueryData<{ isFavourite: boolean }>(
      cacheKeys.signalFavourite(id)
    )
  )

  const favouriteMutation = useMutation<Result>(
    async () => {
      const res = await fetch(`/api/favourites/${product}/toggle-many`, {
        method: "POST",
        body: JSON.stringify({ signalIds }),
      })

      invariant(res.ok, "Failed to favourite signal")

      return await res.json()
    },
    {
      async onSuccess() {
        await Promise.all(
          signalIds.map((signalId) =>
            queryClient.refetchQueries(cacheKeys.signalFavourite(signalId))
          )
        )

        if (!isFavouriteEach.every((signal) => signal?.isFavourite))
          analytics.track("Add Many to Favourites", {
            product,
            signalIds,
          })
      },
    }
  )

  return {
    isFavourite: isFavouriteEach.every((signal) => signal?.isFavourite),
    toggleFavourite: () => favouriteMutation.mutate(),
    isLoading: favouriteMutation.isLoading,
  }
}
