import { Heading, HStack, Text, VStack } from "@chakra-ui/react"
import { HiClock } from "react-icons/hi"
import { GetTalentSignalByID } from "~/utils/db/getTalentSignalByID"
import { BoxIcon } from "~/components/BoxIcon"
import { RenderBoolean } from "~/components/Table/cellRenderers"

interface Props {
  talentSignal: GetTalentSignalByID
}

export const TalentLatestSignal = ({
  talentSignal,
}: Props): JSX.Element | null => {
  if (talentSignal.TalentLatestSignal === null) {
    return null
  }

  return (
    <VStack alignItems="stretch">
      <Heading as="h2" size="sm">
        Latest Signal
      </Heading>
      <HStack>
        <BoxIcon icon={HiClock} />
        <Text fontSize="sm">
          {RenderBoolean({ value: talentSignal.TalentLatestSignal })}
        </Text>
      </HStack>
    </VStack>
  )
}
