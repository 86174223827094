import {
  CompanyHighlight,
  COMPANY_HIGHLIGHTS_PROPS,
  INVESTOR_HIGHLIGHTS_PROPS,
} from "~/consts/signals"
import { GetInvestorByID } from "./db/getInvestorById"

export const sortCompanyHighlights = (
  highlights: string[] | null,
  newHighlights?: string[] | null
) => {
  const ordered = highlights?.sort((a, b) => {
    const groupA = COMPANY_HIGHLIGHTS_PROPS[a as CompanyHighlight]
    const groupB = COMPANY_HIGHLIGHTS_PROPS[b as CompanyHighlight]
    return (groupA?.order ?? 0) - (groupB?.order ?? 0)
  })

  if (!newHighlights || !ordered) return ordered as CompanyHighlight[] | null

  return [
    ...ordered.filter((h) => newHighlights.includes(h as CompanyHighlight)),
    ...ordered.filter((h) => !newHighlights.includes(h as CompanyHighlight)),
  ] as CompanyHighlight[]
}

export const sortInvestorHighlights = (
  highlights: GetInvestorByID["InvestorHighlights"]
) => {
  return highlights?.sort((a, b) => {
    const indexA = Object.keys(INVESTOR_HIGHLIGHTS_PROPS).indexOf(a?.highlight)
    const indexB = Object.keys(INVESTOR_HIGHLIGHTS_PROPS).indexOf(b?.highlight)

    return indexA - indexB
  })
}
