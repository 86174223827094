import {
  Box,
  Button,
  Flex,
  keyframes,
  Spacer,
  useTheme,
} from "@chakra-ui/react"
import { SpecterProducts } from "@prisma/client"
import { FormikProps } from "formik"
import * as R from "ramda"
import { createRef, useCallback, useRef, useState } from "react"
import { ZodFirstPartySchemaTypes, ZodObject } from "zod"
import { FormikErrors, NEW_FEATURED_FILTER_SECTIONS } from "~/components"
import { DeprecatedFieldsAlert } from "~/components/DeprecatedFieldsAlert"
import { MenuItem } from "~/components/MenuItem"
import { getDeprecatedFields } from "~/utils/checkForBackwardsCompatibility"
import { useAnalytics } from "~/utils/hooks/useAnalytics"
import { useEnv } from "~/utils/hooks/useEnv"
import { useProductSignalFilters } from "~/utils/hooks/useProductFilters"
import { useSafeSearchParams } from "~/utils/hooks/useSafeSearchParams"
import { JSONSafeParse } from "~/utils/JSONSafeParse"
import { relativeUrl } from "~/utils/string/url"
import { updateSearchParams } from "~/utils/updateSearchParams"
import { useScroll } from "~/utils/useScroll"
import {
  FiltersSchema,
  mapProductsToRouteName,
  PRODUCT_FILTERS_SCHEMA,
  SignalFilters,
} from "../schemas"
import { AdvancedFilterTab } from "./AdvancedFilterTab"

interface Props<P extends SpecterProducts> {
  product: P
  isOpen: boolean
  onClose: () => void
}

export const AdvancedFiltersModal = <P extends SpecterProducts>({
  product,
  onClose,
}: Props<P>): JSX.Element => {
  const schema = PRODUCT_FILTERS_SCHEMA[product] as FiltersSchema
  const formik = useProductSignalFilters({
    product,
    onSubmit() {
      onClose()
      formik.resetForm()
    },
  }) as FormikProps<SignalFilters[P]>
  const [searchParams] = useSafeSearchParams()

  const [currentTab, setCurrentTab] = useState<number | null>(null)

  const scrollRef = useRef<HTMLDivElement>(null)
  const suspendActiveTabChanging = useRef<boolean>(false)

  const N_TABS = Object.keys(schema.shape).length

  const tabRefs = useRef(
    Array.from({ length: N_TABS }).map(() => createRef<HTMLDivElement>())
  )

  const handleScrollEnd = useCallback(() => {
    suspendActiveTabChanging.current = false
  }, [])

  useScroll({
    target: scrollRef.current ?? undefined,
    onScrollEnd: handleScrollEnd,
  })

  const scrollToTargetAdjusted = useCallback(
    (element: HTMLElement | null, instant?: boolean) => {
      if (!element) return

      const elementPosition = element.offsetTop

      scrollRef.current?.scrollTo({
        top: elementPosition,
        behavior: instant ? "auto" : "smooth",
      })
    },
    []
  )

  const handleChangeTab = useCallback(
    (i: number, instant?: boolean) => {
      const tabRef = tabRefs.current[i]
      // const tabLinkRef = tabLinkRefs.current[i]

      suspendActiveTabChanging.current = true
      // updateActiveLink(tabLinkRef.current?.innerText)

      scrollToTargetAdjusted(tabRef.current, instant)
    },
    [scrollToTargetAdjusted]
  )

  const handleSubmit = (e?: React.FormEvent<HTMLFormElement> | undefined) => {
    formik.handleSubmit(e)
  }

  const analytics = useAnalytics()
  const env = useEnv()
  const query = JSONSafeParse<Record<string, any>>(searchParams.get("query"))
  const deprecatedFields = getDeprecatedFields(product, query)

  const theme = useTheme()

  const animation = keyframes`
  0% {
    background-color: transparent;
  }
  20% {    
    background-color: ${theme.colors.brand[50]};
  }
  100% {    
    background-color: transparent;
  }
`

  const slowlyBlink = `1.5s ${animation} forwards 0.6s`

  return (
    <Box overflow="hidden">
      <form onSubmit={handleSubmit}>
        <Flex h="calc(100vh - 180px)">
          <Flex
            direction="column"
            bgColor="gray.50"
            borderRightWidth={1}
            borderColor="gray.100"
            gap={1}
            p={2}
            w="140px"
            flex="none"
          >
            {Object.keys(schema.shape).map((key, index) => {
              const tabKey = key as keyof typeof schema.shape

              const getAppliedFiltersCount = (
                section: ZodObject<{
                  [itemKey: string]: ZodFirstPartySchemaTypes
                }>
              ) =>
                Object.keys(section.shape).filter(
                  (key) =>
                    formik.values[key as keyof typeof formik.values] !==
                    undefined
                ).length

              const appliedFiltersCount = Object.values(
                schema.shape[tabKey].shape
              ).reduce(
                (acc, sectionSchema) =>
                  acc + getAppliedFiltersCount(sectionSchema),
                0
              )

              const isNew = NEW_FEATURED_FILTER_SECTIONS.includes(key)

              return (
                <MenuItem
                  key={key}
                  isActive={(currentTab ?? 0) === index}
                  onClick={() => {
                    setCurrentTab(index)
                    handleChangeTab(index)
                  }}
                  hideActiveDot
                  count={appliedFiltersCount}
                  isNew={isNew}
                >
                  {key}
                </MenuItem>
              )
            })}
          </Flex>
          <Flex direction="column" flexGrow={1}>
            <Box overflow="auto" flexGrow={1} ref={scrollRef}>
              {deprecatedFields.length > 0 && (
                <Box p={2}>
                  <DeprecatedFieldsAlert
                    deprecatedFields={deprecatedFields}
                    query={query}
                  />
                </Box>
              )}

              <Flex direction="column" py={2}>
                <FormikErrors formik={formik} />

                {Object.entries(schema.shape).map(([key, section], idx) => (
                  <Box
                    key={key}
                    as="section"
                    ref={tabRefs.current[idx]}
                    display="flex"
                    flexDirection="column"
                    gap={4}
                    px={6}
                    py={4}
                    {...(currentTab === idx && { animation: slowlyBlink })}
                  >
                    <AdvancedFilterTab
                      product={product}
                      formik={formik}
                      schema={section}
                    />
                  </Box>
                ))}
              </Flex>
            </Box>

            <Flex gap={2} borderColor="gray.100" borderTopWidth={1} p={4}>
              <Spacer />
              <Button variant="outline" onClick={onClose}>
                Cancel
              </Button>
              <Button
                type="submit"
                colorScheme="brand"
                pointerEvents="all"
                disabled={
                  !formik.dirty || Object.keys(formik.errors).length > 0
                }
                onClick={() => {
                  const newSearchParams = updateSearchParams(searchParams, {
                    query: formik.values,
                  })

                  const searchURL = relativeUrl(
                    `${env.APP_URL}/signals/${mapProductsToRouteName(
                      product
                    )}/feed`,
                    newSearchParams.toString()
                  )

                  analytics.track("Applied a Filter", {
                    menu: "Advanced Filters",
                    product,
                    searchURL,
                    query: formik.values,
                  })
                }}
              >
                Apply Filters
              </Button>
              <Button
                colorScheme="red"
                variant="outline"
                pointerEvents="all"
                onClick={() => formik.setValues({})}
                isDisabled={R.isEmpty(formik.values)}
              >
                Clear All
              </Button>
            </Flex>
          </Flex>
        </Flex>
      </form>
    </Box>
  )
}
