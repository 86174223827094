import { Box } from "@chakra-ui/react"

export const IconInvestor = (props: any) => {
  return (
    <Box as="svg" viewBox="0 0 32 32" {...props}>
      <rect fill="#586eff" width="32" height="32" rx="9" ry="9" />
      <path
        fill="white"
        d="M7,18.82c-1.16-.41-2-1.51-2-2.82v-4c0-1.66,1.34-3,3-3h12c1.3,0,2.4.84,2.82,2h-10.82c-2.76,0-5,2.24-5,5v2.82ZM27,16v4c0,1.66-1.34,3-3,3h-12c-1.66,0-3-1.34-3-3v-4c0-1.66,1.34-3,3-3h12c1.66,0,3,1.34,3,3ZM21,18c0-1.66-1.34-3-3-3s-3,1.34-3,3,1.34,3,3,3,3-1.34,3-3Z"
      />
    </Box>
  )
}
