import {
  Avatar,
  Button,
  ButtonGroup,
  Flex,
  Icon,
  Image,
  Link,
  SimpleGrid,
  Text,
  Tooltip,
} from "@chakra-ui/react"

import { SpecterProducts } from "@prisma/client"
import { Fragment } from "react"
import { HiBriefcase, HiLibrary } from "react-icons/hi"
import {
  ActionMenuIcons,
  ButtonEmail,
  ButtonGithub,
  ButtonLinkedin,
  ButtonTwitter,
  ProductItem,
  SignalCard,
} from "~/components/SignalCard"

import { Education, Position } from "~/utils/db/peopleDBSchemas"
import { getSignalLocation } from "~/utils/getSignalLocation"
import { BoxIcon } from "../BoxIcon"
import { Card, CardProps } from "../Card"
import { CompanyLogo } from "../CompanyLogo"
import { FlexDivider } from "../FlexDivider"

import { Link as RemixLink, useLocation } from "@remix-run/react"
import { HiOutlineShieldCheck } from "react-icons/hi2"
import {
  PeopleHighlight,
  PEOPLE_HIGHLIGHTS,
  PEOPLE_HIGHLIGHTS_PROPS,
} from "~/consts/signals"
import { ensureAbsoluteLink } from "~/utils/string/url"
import { isNullish } from "~/utils/values"
import { getSeniorityColor } from ".."
import { ColoredTag } from "../ColoredTag"
import { PeopleDepartmentIcon } from "../CompanySignalDetailPage/Tabs/TeamBreakdown"

interface Props {
  signal: ProductItem<"people">
  customLink?: string | null
  withReducedInfo?: boolean
}

export const NUM_INDUSTRIES_TO_SHOW = 2
export const NUM_SKILLS_TO_SHOW = 2
export const NUM_HIGHLIGHTS_TO_SHOW = 4

export const PeopleSignalCard = ({
  signal,
  customLink,
  withReducedInfo,
}: Props) => {
  const location = useLocation()

  const detailURL = `./${signal.id}${location.search}`
  const queryAppendChar = location.search ? "&" : "?"

  const croppedHeadline =
    signal.headline &&
    signal.headline?.slice(0, 100) +
      (signal.headline?.length > 100 ? "..." : "")

  const currentPositions = signal.experience?.filter((p) => p.is_current)
  const pastPositions = signal.experience?.filter((p) => !p.is_current)

  const departments = signal.experience?.find(
    (e) => e.is_current && e.departments
  )?.departments

  const sortedHighlights = signal.highlights?.sort((a, b) => {
    const aIndex = PEOPLE_HIGHLIGHTS.indexOf(a as PeopleHighlight)
    const bIndex = PEOPLE_HIGHLIGHTS.indexOf(b as PeopleHighlight)
    return aIndex - bIndex
  })

  return (
    <SignalCard
      customLink={customLink}
      withReducedInfo={withReducedInfo}
      product={SpecterProducts.people}
      signal={signal}
      picture={
        <>
          {/* <Tooltip
            label={signal.is_hiring ? "Hiring" : "Open to work"}
            isDisabled={!(signal.is_hiring || signal.is_open_to_work)}
          > */}
          <Avatar
            src={signal.profile_image_url ?? undefined}
            boxSize={16}
            borderRadius="md"
            icon={<Image borderRadius="md" src="/specter-blank.jpg" />}
            bg="white"
            position="relative"
          >
            {/* {(signal.is_hiring || signal.is_open_to_work) && (
                <Box
                  overflow="hidden"
                  position="absolute"
                  inset="0"
                  _before={{
                    content: '""',
                    position: "absolute",
                    top: 0,
                    bottom: 0,
                    right: "50%",
                    left: "-25%",
                    background: `linear-gradient(transparent, ${
                      signal.is_hiring ? "purple" : "green"
                    }, transparent)`,
                    // animation: `${rotatingAnim} linear infinite 3s`,
                    transformOrigin: "right",
                    transform: "rotate(-45deg)",
                  }}
                  clipPath={clipPathFrame({ borderRadius: 6, borderWidth: 10 })}
                />
              )} */}
          </Avatar>
          {/* </Tooltip> */}
        </>
      }
      // topLeftBadge={
      //   !!signal.rank && (
      //     <CompanyRankBadge rank={signal.rank} month={currentMonth} />
      //   )
      // }
      title={signal.full_name}
      subtitle={
        <Text
          fontSize="sm"
          color="gray.500"
          fontWeight="medium"
          as="span"
          maxW="100px"
        >
          {croppedHeadline}
        </Text>
      }
      description={
        <Text fontSize="xs" color="gray.400" as="span">
          {getSignalLocation({
            location: signal.location,
            region: signal.region,
          })}
        </Text>
        // isDateValid(signal.SignalDate) ? (
        //   <Text fontSize="xs" color="gray.400" as="span">
        //     <Text as="span" fontWeight="semibold">
        //       {getSignalDate(signal.SignalDate)}
        //     </Text>{" "}
        //     -{" "}
        //     {getSignalLocation({
        //       location: signal.Location,
        //       region: signal.Region,
        //     })}
        //   </Text>
        // ) : null
      }
      postTitle={
        <>
          {signal.seniority && (
            <ColoredTag
              verticalAlign="text-bottom"
              color={getSeniorityColor(signal.seniority)}
              rounded="full"
            >
              {signal.seniority}
            </ColoredTag>
          )}
          {departments?.map((department) => (
            <PeopleDepartmentIcon key={department} department={department} />
          ))}
          {signal.years_of_experience && (
            <ColoredTag verticalAlign="text-bottom" color="gray" rounded="full">
              {signal.years_of_experience} years of experience
            </ColoredTag>
          )}
          {(signal.seniority || departments || signal.years_of_experience) && (
            <FlexDivider orientation="vertical" />
          )}
          <TagListWithOverflow
            items={signal.skills}
            maxNItems={NUM_SKILLS_TO_SHOW}
            overflowTo={detailURL}
            // renderFlexDivider
            formatValue={(skill) => skill.name}
            RenderTag={(skill) => (
              <ColoredTag
                key={skill.name}
                display="inline-flex"
                gap={1}
                alignItems="center"
              >
                {skill.name}
                {skill.passed_skill_assessment && (
                  <Tooltip label="Passed skill assessment" fontSize="xs">
                    <Icon as={HiOutlineShieldCheck} />
                  </Tooltip>
                )}
              </ColoredTag>
            )}
          />
        </>
      }
      cardBoxes={
        !withReducedInfo && (
          <>
            {!!currentPositions?.length && (
              <PositionsCard
                title={`Current Position${
                  currentPositions.length > 1 ? "s" : "" ? "s" : ""
                }`}
                positions={currentPositions}
              />
            )}
            {!!pastPositions?.length && (
              <PositionsCard title="Past Positions" positions={pastPositions} />
            )}
            {signal.education && <EducationCard education={signal.education} />}
          </>
        )
      }
      actionButtons={
        <>
          <ButtonLinkedin linkedinUrl={signal.linkedin_url} />
          <ButtonTwitter twitterUrl={signal.twitter_url} />
          <ButtonGithub githubUrl={signal.github_url} />
          <ButtonEmail email={signal?.email} />
        </>
      }
      postActionMenuButtons={
        <ButtonGroup
          size="xs"
          variant="outline"
          display="grid"
          gridTemplateColumns={"repeat(3, 1fr)"}
          spacing={0}
          gap={1}
        >
          <ActionMenuIcons
            product={SpecterProducts.people}
            signal={signal}
            stretchButtons={false}
          />
        </ButtonGroup>
      }
      postActionMenu={
        sortedHighlights && (
          <Flex direction="column" gap={1} minW="130px" position="relative">
            {sortedHighlights
              .slice(0, NUM_HIGHLIGHTS_TO_SHOW)
              .map((highlight) => {
                return (
                  <HighlightSmallItem
                    key={highlight}
                    highlight={highlight as PeopleHighlight}
                    toTab={(tab) => `${detailURL}${queryAppendChar}tab=${tab}`}
                  />
                )
              })}
            {sortedHighlights.length > 5 && (
              <Tooltip
                label={sortedHighlights
                  .slice(5)
                  .map((h) => {
                    const { shortLabel, label } =
                      PEOPLE_HIGHLIGHTS_PROPS[h as PeopleHighlight]
                    return shortLabel ?? label
                  })
                  .join(", ")}
                fontSize="xs"
              >
                <Text fontSize="x-small" color="gray.400" alignSelf="center">
                  and {sortedHighlights.length - 5} more
                </Text>
              </Tooltip>
            )}
          </Flex>
        )
      }
    />
  )
}

export const HighlightSmallItem = ({
  highlight,
  toTab,
  ...props
}: {
  highlight: PeopleHighlight
  toTab?: (tab?: number) => string
} & CardProps) => {
  const highlightProps = PEOPLE_HIGHLIGHTS_PROPS[highlight as PeopleHighlight]
  if (!highlight || !highlightProps) return null

  const { label, shortLabel, colorScheme, tab = 0 } = highlightProps

  // const isNew =
  //   signal.newHighlights && signal.newHighlights.includes(highlight)

  // if (hide && !isNew) return null

  return (
    <Card
      p={1}
      px={2}
      position="relative"
      bgColor={`${colorScheme}.50`}
      borderColor={`${colorScheme}.100`}
      fontWeight="bold"
      to={toTab?.(tab)}
      {...props}
    >
      {/* {isNew && (
    <NotificationBadge
      value="New"
      color={`${colorScheme}.400`}
    />
  )} */}

      <Text
        as="h2"
        color={`${colorScheme}.500`}
        fontSize="x-small"
        whiteSpace="nowrap"
        flexGrow={1}
      >
        {shortLabel ?? label}
      </Text>
    </Card>
  )
}

export const TagListWithOverflow = <T,>({
  items,
  maxNItems,
  overflowTo,
  RenderTag = (item) => item as string,
  formatValue = (item) => item as string,
  renderFlexDivider,
}: {
  items?: T[] | null
  maxNItems: number
  overflowTo?: string
  RenderTag?: (item: T) => React.ReactNode
  formatValue?: (item: T) => string
  renderFlexDivider?: boolean
}) => {
  const overflowCount = (items?.length ?? 0) - maxNItems

  if (isNullish(items) || items.length === 0) return null

  return (
    <>
      {items
        .slice(0, maxNItems) // * Only show the first N tags
        .map(RenderTag)}
      {items.length > maxNItems && (
        <Button
          as={RemixLink}
          to={overflowTo ?? ""}
          variant="link"
          minW="fit-content"
          whiteSpace="nowrap"
        >
          <Tooltip
            label={items?.slice(-overflowCount).map(formatValue).join(", ")}
            fontSize="xs"
          >
            <Text as="span">+{overflowCount}</Text>
          </Tooltip>
        </Button>
      )}
      {renderFlexDivider && <FlexDivider orientation="vertical" />}
    </>
  )
}

export const PositionsCard = ({
  title,
  positions,
}: {
  title?: string
  positions: Position[]
}) => {
  return (
    <Card p={4} display="flex" flexDirection="column" gap={2} maxW="500px">
      {title && (
        <Text as="h2" color="gray.800" fontSize="sm" fontWeight="bold">
          {title}
        </Text>
      )}

      <Flex gap={2}>
        {positions?.slice(0, 2).map((position, i) => (
          <Fragment key={position.specter_company_id + position.title}>
            {i > 0 && <FlexDivider orientation="vertical" />}
            <SimpleGrid templateColumns="auto auto" gap={2}>
              {position.domain ? (
                <CompanyLogo domain={position.domain} size={8} />
              ) : (
                <BoxIcon icon={HiBriefcase} size={8} />
              )}
              <Flex
                direction="column"
                justifyContent="center"
                fontSize="xs"
                overflow="hidden"
                flexShrink={1}
              >
                {position.company_linkedin_url ? (
                  <Link
                    color="brand.500"
                    {...(position.specter_company_id
                      ? {
                          as: RemixLink,
                          to: `/signals/company/feed/${position.specter_company_id}/f`,
                          target: "_blank",
                        }
                      : {
                          href: ensureAbsoluteLink(
                            position.company_linkedin_url
                          ),
                          target: "_blank",
                          to: "",
                        })}
                    // href={ensureAbsoluteLink(position.company_linkedin_url)}
                    // target="_blank"
                    onClick={(e) => e.stopPropagation()}
                    fontWeight="semibold"
                    overflow="hidden"
                    whiteSpace="nowrap"
                    textOverflow="ellipsis"
                  >
                    {position.company_name}
                  </Link>
                ) : (
                  <Text
                    fontWeight="semibold"
                    overflow="hidden"
                    whiteSpace="nowrap"
                    textOverflow="ellipsis"
                  >
                    {position.company_name}
                  </Text>
                )}
                <Text
                  overflow="hidden"
                  whiteSpace="nowrap"
                  textOverflow="ellipsis"
                >
                  {position.title}
                </Text>
              </Flex>
            </SimpleGrid>
          </Fragment>
        ))}
      </Flex>
    </Card>
  )
}

export const EducationCard = ({
  title = "Education",
  education,
}: {
  title?: string
  education: Education[]
}) => {
  return (
    <Card p={4} display="flex" flexDirection="column" gap={2} maxW="500px">
      {title && (
        <Text as="h2" color="gray.800" fontSize="sm" fontWeight="bold">
          {title}
        </Text>
      )}

      <Flex gap={2}>
        {education?.slice(0, 2).map((item, i) => (
          <Fragment key={`${item.school_id} + ${item.degree}`}>
            {i > 0 && <FlexDivider orientation="vertical" />}
            <SimpleGrid templateColumns="auto auto" gap={2}>
              {item.logo_url ? (
                <CompanyLogo source={item.logo_url} size={8} />
              ) : (
                <BoxIcon icon={HiLibrary} size={8} />
              )}
              <Flex
                direction="column"
                justifyContent="center"
                fontSize="xs"
                overflow="hidden"
                flexShrink={1}
              >
                {item.school_linkedin_url ? (
                  <Link
                    color="brand.500"
                    //   {...(talentSignal.PastCompany?.Company?.id
                    //     ? {
                    //         as: RemixLink,
                    //         to: `/signals/company/feed/${talentSignal.PastCompany.Company.id}/f`,
                    //       }
                    //     : {
                    //         href: talentSignal.PastCompanyWebsite ?? undefined,
                    //         target: "_blank",
                    //         to: "",
                    //       })}
                    href={ensureAbsoluteLink(item.school_linkedin_url)}
                    target="_blank"
                    onClick={(e) => e.stopPropagation()}
                    fontWeight="semibold"
                    overflow="hidden"
                    whiteSpace="nowrap"
                    textOverflow="ellipsis"
                  >
                    {item.school_name}
                  </Link>
                ) : (
                  <Text
                    fontWeight="semibold"
                    overflow="hidden"
                    whiteSpace="nowrap"
                    textOverflow="ellipsis"
                  >
                    {item.school_name}
                  </Text>
                )}
                <Text
                  overflow="hidden"
                  whiteSpace="nowrap"
                  textOverflow="ellipsis"
                >
                  {item.degree}
                </Text>
              </Flex>
            </SimpleGrid>
          </Fragment>
        ))}
      </Flex>
    </Card>
  )
}
